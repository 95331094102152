@media (max-width: 767px) {
    .word-generator-mobile-box {
        width: calc( 100%);
        border-radius: 10px;
        overflow: hidden;
        background-color: var(--default-blue);
        background-size: 100% 50%;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        margin-bottom: -30px;
    }
    .word-generator-mobile-header-section {
        height: 100px;
        position: relative;
    }
    .word-generator-mobile-header-section div {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .word-generator-mobile-header-section div * {
        color: var(--default-blue);
        text-shadow: 1px 1px 0#fff, -1px 1px 0#fff, 1px -1px 0#fff, -1px -1px 0#fff, 0px 1px 0#fff, 0px -1px 0#fff, -1px 0px 0#fff, 1px 0px 0#fff, 2px 2px 0#fff, -2px 2px 0#fff, 2px -2px 0#fff, -2px -2px 0#fff, 0px 2px 0#fff, 0px -2px 0#fff, -2px 0px 0#fff, 2px 0px 0#fff, 1px 2px 0#fff, -1px 2px 0#fff, 1px -2px 0#fff, -1px -2px 0#fff, 2px 1px 0#fff, -2px 1px 0#fff, 2px -1px 0#fff, -2px -1px 0#fff;
    }
    .word-generator-mobile-button-container {
        width: 100%;
        height: 150px;
        position: relative;
        margin-top: 60px;
    }
    .word-generator-mobile-play-button {
        width: 100px;
        height: 100px;
        margin-left: calc(50% - 50px);
        border-radius: 50% 50%;
        background: radial-gradient( #e6daca 0%, #e9bc80 100%);
        animation-name: shakeUp;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        box-shadow: 0px 0px 30px 15px var(--dark-blue);
    }
    .word-generator-mobile-text-button {
        width: 100%;
        line-height: 100px;
        text-align: center;
        cursor: pointer;
        z-index: 1;
    }
     :root {
        --spin-text-shadow: rgb(13 24 60 / 2%);
    }
    .word-generator-mobile-text-button span {
        cursor: pointer;
        text-align: center;
        color: var(--default-red);
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 900;
        text-shadow: 1px 1px 0 var(--spin-text-shadow), -1px 1px 0 var(--spin-text-shadow), 1px -1px 0 var(--spin-text-shadow), -1px -1px 0 var(--spin-text-shadow), 0px 1px 0 var(--spin-text-shadow), 0px -1px 0 var(--spin-text-shadow), -1px 0px 0 var(--spin-text-shadow), 1px 0px 0 var(--spin-text-shadow), 2px 2px 0 var(--spin-text-shadow), -2px 2px 0 var(--spin-text-shadow), 2px -2px 0 var(--spin-text-shadow), -2px -2px 0 var(--spin-text-shadow), 0px 2px 0 var(--spin-text-shadow), 0px -2px 0 var(--spin-text-shadow), -2px 0px 0 var(--spin-text-shadow), 2px 0px 0 var(--spin-text-shadow), 1px 2px 0 var(--spin-text-shadow), -1px 2px 0 var(--spin-text-shadow), 1px -2px 0 var(--spin-text-shadow), -1px -2px 0 var(--spin-text-shadow), 2px 1px 0 var(--spin-text-shadow), -2px 1px 0 var(--spin-text-shadow), 2px -1px 0 var(--spin-text-shadow), -2px -1px 0 var(--spin-text-shadow);
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg)
        }
        50% {
            transform: rotate(360deg)
        }
        100% {
            transform: rotate(0deg)
        }
    }
    .word-generator-mobile-body-reel-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        padding: 0px 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .word-generator-mobile-body-reel-container-line-1 {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .word-generator-mobile-body-reel-container-line-2 {
        width: 100%;
        margin-left: 10%;
        display: flex;
        padding: 10px 10px;
        flex-wrap: wrap;
    }
    .reel-box-mobile {
        width: 31.33%;
        margin: 1%;
    }
    .reel-box-mobile-line-2 {
        width: 48%;
        margin: 1%;
    }
    .ticket-section-word-generator-mobile-box {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    }
    .word-generator-mobile-ticket-frame {
        padding: 15px;
        border-radius: 10px;
        position: relative;
        background-color: #e9bc80;
        background: radial-gradient(circle, #fae1c1 0%, #f7d5a8 10%, #f3ca94 40%, #ecc28b 100%);
        box-shadow: 0px 0px 20px 20px var(--dark-blue);
    }
    .word-generator-mobile-ticket-frame-stripes {
        position: relative;
    }
    .word-generator-mobile-ticket-frame-wrap {
        border: dashed 3px red;
        border-radius: 10px;
        display: flex;
        position: relative;
        z-index: 0;
        height: 100%;
    }
    .word-generator-mobile-ticket-frame-wrap .word-generator-mobile-body-reel-container {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
    }
    .word-generator-mobile-ticket-number {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        border-right: dashed 3px red;
        color: var(--default-blue);
        font-size: 18px;
        font-weight: 900;
        text-align: center;
    }
    .word-generator-mobile-ticket-title {
        position: absolute;
        top: 0;
        left: 0;
        color: var(--default-blue);
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        padding-top: 20px;
    }
    .word-generator-mobile-ticket-stars {
        position: absolute;
        bottom: 0px;
        left: 0;
        color: var(--default-red);
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
    }
}