.payment-card-section-home-text-box-wrap {
    margin-top: 0%;
}

.payment-card-section-home-box-row {
    height: 100%;
    content: '';
    background-size: 60% 60%;
    background-position: 130% 10%;
    background-repeat: no-repeat;
}

.payment-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.payment-card-section-home-text-box p {
    font-size: 18px;
    text-align: center;
}

.payment-card-section-home-text-box h1 {
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 35px;
}

.payment-card-section-home-text-box {
    width: 100%;
}

@media (min-width: 768px) {
    .payment-card-section-home-text-box-wrap {
        margin-top: 0%;
    }
    .payment-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        padding-bottom: 10%;
    }
    .payment-card-section-home-box-row::before {
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/paymentSectionBackGround.svg');
        background-size: 20% 20%;
        background-position: 0% 100%;
        background-repeat: no-repeat;
        opacity: 1;
    }
    .payment-card-section-home-text-box * {
        text-align: left;
    }
    .payment-card-section-home-text-box p {
        font-size: 22px;
        text-align: left;
    }
    .payment-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 900;
        margin-bottom: 50px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
    .payment-card-section-home-text-box {
        width: 100%;
        position: relative;
        margin-bottom: 60px;
    }
}

@media (min-width: 1200px) {
    .payment-card-section-home-text-box p {
        font-size: 22px;
    }
    .payment-card-section-home-text-box h1 {
        font-size: 70px;
        margin-bottom: 70px;
    }
}