.report-card-section-home-box-row {
    width: 100%;
    height: 100%;
    content: '';
    background-size: 60% 60%;
    background-position: 130% 10%;
    background-repeat: no-repeat;
}

#report-card-section-home {
    margin-top: 100px;
}

.report-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.report-card-section-home-text-box p {
    font-size: 18px;
}

.report-card-section-home-text-box h1 {
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

@media (max-width: 420px) {
    .report-card-section-home-text-box {
        width: 100%;
        margin-top: -20% !important;
    }
    .report-card-section-home-text-box h1 {
        margin-bottom: 30px;
    }
    #lottie-box-home-report-image {
        margin-top: -30%;
    }
}

#lottie-box-home-report-image {
    width: 140%;
    margin-left: -20%;
}

@media(max-width:767px) {
    #lottie-box-home-report-image {
        margin-top: -30%;
    }
    .report-card .custom-card {
        width: 100%;
        height: auto;
        padding: 30px;
        box-shadow: 0px 0px 10px 0px #333;
        border-radius: 10px;
        border: 0;
        position: relative;
        overflow: hidden;
    }
    .report-card .custom-card::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 35%;
        top: 0;
        left: 0;
        border: 0;
    }
}

@media (min-width: 768px) {
    .report-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../assets/reportSectionBackGround.svg');
        background-size: 20% 20%;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 12%;
    }
    .report-card-section-home-text-box * {
        text-align: left;
    }
    .report-card-section-home-text-box p {
        font-size: 22px;
    }
    .report-card-section-home-text-box h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 50px;
    }
    .report-card-section-home-text-box {
        width: 100%;
        position: relative;
    }
    #lottie-box-home-report-image {
        width: 100%;
        margin-left: -15%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .report-card-section-home-box-row {
        display: flex;
    }
}

@media (min-width: 1200px) {
    .report-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 900;
        margin-bottom: 70px;
    }
}