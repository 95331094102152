.page-404 {
    margin-top: 60px;
}

.page404-img {
    width: 100%;
}

.page404-text {
    text-align: center;
}

.page404-link,
.page404-link a {
    color: var(--default-blue);
    text-align: center;
    text-decoration: none;
}