.full-tape-box {
    width: 100%;
    min-height: 230px;
    background-color: #fff;
    background-image: url('../assets/bannerBackgroundImage2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: inset -10px -30px 10px -20px rgb(255, 255, 255);
}

.full-left-tape-img-box img {
    padding: 20%;
    width: 100%;
}

.full-left-tape-img-box content {
    padding: 20%;
    width: 100%;
}

.full-left-tape-text-box {
    position: relative;
}

.full-left-tape-text-box * {
    color: #333;
    text-align: center;
}

.full-left-tape-text-box {
    padding: 0px;
}

.full-left-tape-text-box div {
    width: 100%;
}

.full-left-tape-text-box h2 {
    font-size: 18px;
    font-weight: 500;
}

.fade-moving-right-to-left {
    display: flex;
    flex-wrap: wrap;
    animation-name: fade-moving-right-to-left;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

@media (min-width: 768px) {
    .full-tape-box {
        width: 100%;
        min-height: 350px;
        background-color: #fff;
        background-image: url('../assets/bannerBackgroundImage2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-shadow: inset -10px -30px 10px -20px rgb(255, 255, 255);
    }
    .full-left-tape-img-box img {
        padding: 20%;
        width: 100%;
    }
    .full-left-tape-img-box content {
        padding: 20%;
        width: 100%;
    }
    .full-left-tape-text-box {
        position: relative;
    }
    .full-left-tape-text-box * {
        color: #333;
        text-align: center;
    }
    .full-left-tape-text-box div {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .full-left-tape-text-box h1 {
        font-size: 60px;
        text-transform: uppercase;
        font-weight: 700;
    }
}