.custom-card-contact {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.contact-section-card-header {
    padding: 0px;
}

.contact-form-body-wrap {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    margin-bottom: 100px;
    box-shadow: 0px 10px 20px 2px #333;
    padding-bottom: 100px;
}

.contact-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.contact-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.contact-section-text-box {
    padding: 20px;
    background-image: url('../assets/contact-section-background.svg');
    background-size: 50% auto;
    background-position: 100%, 120%;
    background-repeat: no-repeat;
}

.contact-section-text-box p,
.contact-section-text-box h3 {
    color: #fff;
    text-align: center;
}

.contact-section-text-box p {
    width: 100%;
    font-size: 18px;
}

.contact-section-text-box .input-group-prepend * {
    height: 100%;
}

.contact-section-text-box .btn-block {
    margin-top: 15px;
    width: 100%;
}

.contact-section-text-box .fa {
    width: 24px;
}

.contact-section-text-box input,
.contact-section-text-box textarea {
    border-radius: 0px 5px 5px 0px !important;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact-section-text-box textarea {
    border-radius: 5px !important;
}

@media (min-width: 768px) {
    .contact-section-text-box {
        padding: 30px;
    }
    .contact-section-card-header {
        padding-top: 30px;
    }
    .contact-section-text-box p {
        width: 70%;
        margin-left: 15%;
        text-align: center;
        font-size: 22px;
    }
    .contact-section-card-header h1 {
        font-size: 60px;
    }
}