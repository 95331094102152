.custom-card-ticket {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.ticket-section-card-header {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, var(--dark-blue) 100%);
    padding: 30px;
    min-height: 250px;
    position: relative;
}

.ticket-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.ticket-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.ticket-section-text-box {
    padding: 20px;
}

.ticket-section-text-box p,
.ticket-section-text-box b {
    width: 100%;
    color: var(--default-blue);
    text-shadow: 0px 2px 2px rgba(231, 227, 227, 0.1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-align: center;
    font-size: 18px;
}

.ticket-section-text-box b {
    color: var(--default-red);
}

@media (min-width: 768px) {
    .ticket-section-text-box {
        padding: 30px;
        background-image: url('../assets/tickets-section-background.svg');
        background-size: 35% 45%;
        background-position: 100%, 120%;
        background-repeat: no-repeat;
    }
    .ticket-section-text-box p,
    .ticket-section-text-box b {
        width: 70%;
        color: var(--default-blue);
        text-align: center;
        font-size: 22px;
    }
    .ticket-section-text-box b {
        color: var(--default-red);
    }
    .ticket-section-card-header h1 {
        font-size: 60px;
    }
}