.player-card-section-home-text-box-wrap {
    margin-top: -15%;
}

.player-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.player-card-section-home-text-box p {
    font-size: 18px;
}

.player-card-section-home-text-box h1 {
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 35px;
    margin-bottom: 30px;
}

.player-card-section-home-text-box {
    width: 100%;
}

@media (min-width: 768px) {
    .player-card-section-home-text-box-wrap {
        margin-top: 0%;
    }
    .player-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        padding-bottom: 10%;
    }
    .player-card-section-home-box-row::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../assets/playerSectionBackGround.svg');
        background-size: 28% 28%;
        background-position: 100% 105%;
        background-repeat: no-repeat;
    }
    .player-card-section-home-text-box * {
        color: #fff;
        text-align: left;
    }
    .player-card-section-home-text-box p {
        width: 100%;
        height: 100%;
        font-size: 22px;
    }
    .player-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 600 !important;
        margin-bottom: 50px;
        padding: 15px;
        margin-left: -15px;
        border-radius: 10px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
    .player-card-section-home-text-box {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

@media (min-width: 1200px) {
    .player-card-section-home-text-box h1 {
        font-size: 70px;
        font-weight: 900;
        margin-bottom: 70px;
    }
    .player-card-section-home-text-box p {
        font-size: 22px;
    }
}