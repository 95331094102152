.word-generator-box {
    width: calc( 100%);
    border-radius: 10px;
    overflow: hidden;
    background-image: url('../assets/word-generaror-background.svg');
    background-size: 100% 100%;
    background-position: 0%;
    background-repeat: no-repeat;
    margin-bottom: -30px;
    position: relative;
}

.word-generator-box:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
}

.word-generator-header-section {
    height: 300px;
    position: relative;
}

.word-generator-header-section div {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.word-generator-header-section div * {
    color: var(--default-blue);
    text-shadow: 1px 1px 0#fff, -1px 1px 0#fff, 1px -1px 0#fff, -1px -1px 0#fff, 0px 1px 0#fff, 0px -1px 0#fff, -1px 0px 0#fff, 1px 0px 0#fff, 2px 2px 0#fff, -2px 2px 0#fff, 2px -2px 0#fff, -2px -2px 0#fff, 0px 2px 0#fff, 0px -2px 0#fff, -2px 0px 0#fff, 2px 0px 0#fff, 1px 2px 0#fff, -1px 2px 0#fff, 1px -2px 0#fff, -1px -2px 0#fff, 2px 1px 0#fff, -2px 1px 0#fff, 2px -1px 0#fff, -2px -1px 0#fff;
}

.word-generator-body-reel-container {
    width: 100%;
    display: flex;
    padding: 20px 20px;
}

.reel-box {
    width: 18%;
    margin: 1%;
    height: 130px;
}

.word-generator-button-container {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: 60px;
}

.word-generator-play-button {
    width: 160px;
    height: 160px;
    margin-left: calc(50% - 100px);
    border-radius: 50% 50%;
    background: radial-gradient( #e6daca 0%, #e9bc80 100%);
    animation-name: shakeUp;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 30px 15px var(--dark-blue);
}

.word-generator-text-button {
    width: 100%;
    line-height: 160px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

:root {
    --spin-text-shadow: rgb(13 24 60 / 2%);
}

.word-generator-text-button span {
    cursor: pointer;
    text-align: center;
    color: var(--default-red);
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 900;
    text-shadow: 1px 1px 0 var(--spin-text-shadow), -1px 1px 0 var(--spin-text-shadow), 1px -1px 0 var(--spin-text-shadow), -1px -1px 0 var(--spin-text-shadow), 0px 1px 0 var(--spin-text-shadow), 0px -1px 0 var(--spin-text-shadow), -1px 0px 0 var(--spin-text-shadow), 1px 0px 0 var(--spin-text-shadow), 2px 2px 0 var(--spin-text-shadow), -2px 2px 0 var(--spin-text-shadow), 2px -2px 0 var(--spin-text-shadow), -2px -2px 0 var(--spin-text-shadow), 0px 2px 0 var(--spin-text-shadow), 0px -2px 0 var(--spin-text-shadow), -2px 0px 0 var(--spin-text-shadow), 2px 0px 0 var(--spin-text-shadow), 1px 2px 0 var(--spin-text-shadow), -1px 2px 0 var(--spin-text-shadow), 1px -2px 0 var(--spin-text-shadow), -1px -2px 0 var(--spin-text-shadow), 2px 1px 0 var(--spin-text-shadow), -2px 1px 0 var(--spin-text-shadow), 2px -1px 0 var(--spin-text-shadow), -2px -1px 0 var(--spin-text-shadow);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }
    50% {
        transform: rotate(360deg)
    }
    100% {
        transform: rotate(0deg)
    }
}

.ticket-section-word-generator-box {
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    padding: 5%;
}

.word-generator-ticket-frame {
    padding: 15px;
    border-radius: 10px;
    position: relative;
    background-color: #e9bc80;
    background: radial-gradient(circle, #fff 0%, #f7d5a8 10%, #f3ca94 40%, #ecc28b 100%);
    box-shadow: 0px 0px 20px 20px var(--dark-blue);
}

.word-generator-ticket-frame-stripes {
    position: relative;
}

.word-generator-ticket-frame-wrap {
    border: dashed 3px red;
    border-radius: 10px;
    display: flex;
    position: relative;
    z-index: 0;
}

.word-generator-ticket-frame-wrap .word-generator-body-reel-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.word-generator-ticket-number {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    border-right: dashed 3px red;
    padding-right: 10px;
    color: var(--default-blue);
    font-size: 40px;
    font-weight: 900;
    text-align: center;
}

.word-generator-ticket-title {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--default-blue);
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding-top: 20px;
}

.word-generator-ticket-stars {
    position: absolute;
    bottom: 20px;
    left: 0;
    color: var(--default-red);
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
}

.word-generator-ticket-frame::before {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100px;
    height: 100px;
    padding: 4px;
    z-index: 1;
    content: '';
    border-radius: 50% 50%;
    background: linear-gradient(90deg, transparent 0%, transparent 46%, var(--dark-blue) 46%, var(--dark-blue) 100%);
    transform: translate(-50%, -50%);
}

.word-generator-ticket-frame::after {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100px;
    height: 100px;
    z-index: 0;
    content: '';
    border-radius: 50% 50%;
    background: linear-gradient(270deg, transparent 0%, transparent 48%, var(--dark-blue) 48%, var(--dark-blue) 100%);
    transform: translate(50%, -50%);
}