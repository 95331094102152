@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body * {
    font-family: 'Roboto', sans-serif;
    color: var(--default-secondary);
}

#root {
    max-width: 100vw;
    overflow: hidden;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: var(--default-blue);
    border: var(--default-blue);
    outline: 0px !important;
}

.btn-primary * {
    color: #fff;
}

.btn-blue,
.btn-blue:hover,
.btn-blue:active,
.btn-blue:visited,
.btn-blue:focus {
    background-color: var(--dark-blue);
    border: var(--dark-blue);
    outline: 0px !important;
}

.btn-blue {
    color: #fff;
    font-weight: 700;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus {
    background-color: var(--default-red);
    border: var(--default-red);
    outline: 0px !important;
}

.btn-danger {
    color: #fff;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:visited,
.btn-light:focus {
    background-color: #f0c790;
    border-color: #f0c790;
    outline: 0px !important;
    box-shadow: 0px 0px 10px 1px var(--dark-blue);
    transform: scale(1, 1.2);
}

.btn-light {
    color: var(--dark-blue);
    font-weight: 900;
}

.btn-light:hover {
    color: var(--default-blue);
    font-weight: 600;
    transition: all .5s ease-in-out;
    transform: scale(1.01, 1.3);
}