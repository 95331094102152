.tom-bola-partners-wrap {
    position: relative;
    min-height: 120px;
}

.tom-bola-partners-hero-banner-section-hidden {
    opacity: 0 !important;
    transition: opacity 1s ease-in-out;
}

.tom-bola-partners-hero-banner-section-hidden * {
    pointer-events: none !important;
}

.tom-bola-partners-hero-banner-section {
    width: 100%;
    opacity: 1;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 1s ease-in-out;
    text-align: center;
    z-index: 999;
}

.tom-bola-partners-hero-banner-section h1 {
    color: #fff;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.tom-bola-partners-hero-banner-section img {
    cursor: pointer;
}

@media (max-width:768px) {
    .tom-bola-partners-wrap {
        min-height: 120px;
    }
    .tom-bola-partners-hero-banner-section {
        transform: translate(-50%, -60%);
    }
}

@media (max-width:420px) {
    .tom-bola-partners-wrap {
        min-height: 100px;
    }
    .tom-bola-partners-hero-banner-section {
        transform: translate(-50%, -80%);
    }
}

@media (max-width:320px) {
    .tom-bola-partners-wrap {
        min-height: 150px;
    }
    .tom-bola-partners-hero-banner-section {
        transform: translate(-50%, -60%);
    }
}