.footer {
    background-color: var(--dark-blue);
    width: 100%;
    min-height: 30vh;
    color: var(--default-secondary);
    text-align: center;
    padding: 50px;
}

.footer a {
    text-decoration: none;
}

.footer a:hover {
    color: var(--high-blue);
}