@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
 :root {
    --reel-slot-width: 200px;
    --reel-slot-height: 130px;
    --noWinners-color_0: var(--default-red);
    --noWinners-color_1: var(--default-red);
    --noWinners-color_2: var(--default-red);
    --noWinners-color_3: var(--default-red);
    --noWinners-color_4: var(--default-red);
    --noWinners-opacity_0: 1;
    --noWinners-opacity_1: 1;
    --noWinners-opacity_2: 1;
    --noWinners-opacity_3: 1;
    --noWinners-opacity_4: 1;
    /* starts whit - later change to  #0e1336*/
}

.reel-main-box {
    width: var(--reel-slot-width);
    height: var(--reel-slot-height);
    position: absolute;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 2px #503a1d;
    border-radius: 10px;
}

.reel-slot-container {
    border-radius: 10px;
    overflow: hidden;
    width: var(--reel-slot-width);
    height: var(--reel-slot-height);
    background-color: #fff;
    background: radial-gradient( #f5e3cc 0%, #ecc28b 70%, #af8a59 98%, #000 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
}

.reel-slot-container-shadow {
    width: 100%;
    height: 100%;
    background-position: 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.reel-slot-container-gloss:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets//gloss.png');
    opacity: 0.3;
    background-position: 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.reel-slot-container-content {
    position: absolute;
    width: 100%;
    transition: all 3s ease-in-out;
}

.reel-slot-container-content-item {
    width: 100%;
    height: calc(var(--reel-slot-height) * 0.45);
    text-align: center;
    transform: scale(1, 1);
}

.reel-slot-container-content-item-emoji {
    width: 100%;
    height: calc(var(--reel-slot-height) * 0.5);
    background-size: auto 80%;
    background-position: 50%;
    background-repeat: no-repeat;
}

.reel-slot-container-content-item span {
    font-family: 'Anton', cursive;
    color: var(--default-red);
    font-size: 44px;
}

.reel-slot-container-content-item .reel-slot-container-content-item-black {
    font-family: 'Sigmar One', cursive;
    color: #000;
    font-size: 22px;
}

.noWinners_0 span {
    color: var(--noWinners-color_0);
    /* opacity: var(--noWinners-opacity_0); */
    transition: color 2s ease-in-out;
}

.noWinners_1 span {
    color: var(--noWinners-color_1);
    /* opacity: var(--noWinners-opacity_1); */
    transition: color 2s ease-in-out;
}

.noWinners_2 span {
    color: var(--noWinners-color_2);
    /* opacity: var(--noWinners-opacity_1); */
    transition: color 2s ease-in-out;
}

.noWinners_3 {
    color: var(--noWinners-color_3);
    opacity: var(--noWinners-opacity_3);
    transition: opacity 1s ease-out;
}

.noWinners_4 {
    color: var(--noWinners-color_4);
    opacity: var(--noWinners-opacity_4);
    transition: opacity 1s ease-out;
}