.intro-card {
    box-shadow: 0px 0px 10px -5px #333;
    margin-top: -100px;
}

.intro-card-header {
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
}

.intro-card-header h3 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.intro-card-content {
    padding: 30px;
    font-size: 18px;
}

@media (min-width:768px) {
    .intro-card-content * {
        font-size: 18px;
    }
}

@media (min-width:992px) {
    .intro-card-content * {
        font-size: 22px;
    }
}