.custom-card-compliance {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.compliance-section-card-header {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    padding: 40px;
    height: 100px;
}

.compliance-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.compliance-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.compliance-section-text-box {
    padding: 20px;
    /* background-image: url('../assets/compliances-section-background.svg'); */
    background-size: 50% 50%;
    background-position: 120%, 120%;
    background-repeat: no-repeat;
    display: block;
}

.compliance-image-wrap {
    display: flex;
    flex-wrap: wrap;
}

.compliance-image-1,
.compliance-image-2,
.compliance-image-3 {
    content: '';
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 3.5s;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.compliance-image-2 {
    background-image: url('../assets/complianceLogos/ukgc-logo.png');
    background-size: auto 100%;
}

.compliance-image-3 {
    background-image: url('../assets/complianceLogos/eugdpr-compliant.png');
    background-size: 100% auto;
}

.compliance-image-1,
.compliance-image-4 {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    margin-bottom: 15px;
}

.compliance-image-1:after,
.compliance-image-4:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 0%;
    background-color: transparent;
    background-image: url('../assets/complianceLogos/spm_logo.png');
}

.compliance-image-1:after {
    background-image: url('../assets/complianceLogos/RNG\ certified.png');
}

.compliance-image-1::before,
.compliance-image-4::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(0deg, var(--dark-blue) 0%, var(--default-blue) 50%, var(--dark-blue) 100%);
}

.compliance-image-1::before {
    background: #c3c3c3
}

@keyframes shakeUp {
    10%,
    90% {
        transform: translate3d(0, -1px, 0);
    }
    20%,
    80% {
        transform: translate3d(0, 2px, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(0, -4px, 0);
    }
    40%,
    60% {
        transform: translate3d(0, 4px, 0);
    }
}

.compliance-section-text-box p {
    width: 100%;
    color: var(--default-blue);
    text-align: center;
    font-size: 18px;
}

.compliance-acronyms * {
    text-align: center;
    list-style: none;
}

@media (min-width: 768px) {
    .compliance-section-text-box {
        padding: 30px;
        background-image: url('../assets/compliance-section-background.svg');
        background-size: 28% 28%;
        background-position: 100% 100%;
        padding-bottom: 10%;
    }
    .compliance-section-card-header {
        background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
        padding: 30px;
        height: 120px;
    }
    .compliance-section-text-box {
        display: flex;
        flex-wrap: wrap;
    }
    .compliance-section-text-box p {
        width: 70%;
        margin-left: 5%;
        color: var(--default-blue);
        text-align: left;
        font-size: 22px;
    }
    .compliance-section-card-header h1 {
        font-size: 60px;
    }
}