@keyframes blinkBackground {
    0% {
        background-image: url('../assets/bannerBlink/1.svg');
        transform: scale(1);
        background-position: 90% 100%;
    }
    20% {
        background-image: url('../assets/bannerBlink/1.svg');
        transform: scale(1);
        opacity: 1;
    }
    25% {
        transform: scale(.9);
        background-image: url('../assets/bannerBlink/2.svg');
        background-position: 0% 100%;
        opacity: .9;
    }
    45% {
        transform: scale(.9);
        background-image: url('../assets/bannerBlink/2.svg');
        opacity: .1;
    }
    50% {
        transform: scale(1);
        background-image: url('../assets/bannerBlink/3.svg');
        background-position: 90% 0%;
        opacity: .1;
    }
    70% {
        transform: scale(1);
        background-image: url('../assets/bannerBlink/3.svg');
        background-position: 0% 10%;
        opacity: .9;
    }
    75% {
        transform: scale(.9);
        background-image: url('../assets/bannerBlink/4.svg');
        opacity: .0;
    }
    95% {
        transform: scale(.9);
        background-image: url('../assets/bannerBlink/4.svg');
        opacity: .9;
    }
    100% {
        transform: scale(1);
        background-image: url('../assets/bannerBlink/1.svg');
    }
}

.hero-banner-box:before {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
}

.hero-banner-box {
    width: 100%;
    height: 100vh;
    background-image: url('../assets/bannerBackground/background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    position: relative;
}

.hero-banner-content-background-blink:before {
    width: 100%;
    height: 100%;
    background-size: 60%, 60%;
    background-position: 50%;
    background-repeat: no-repeat;
    animation-name: blinkBackground;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    content: "";
    position: absolute;
    z-index: 0;
    opacity: 1;
}

.hero-banner-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-banner-content-text-box {
    padding: 60px 30px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
}

.hero-banner-content-text-box-wrap {
    position: relative;
    z-index: 2;
    text-align: center;
}

.hero-banner-content-text-box h1,
.hero-banner-content-text-box h3 {
    color: #fff;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.hero-banner-content-text-box h1 {
    font-size: 40px;
    text-transform: uppercase;
}

.hero-banner-link a {
    text-decoration: none;
}

.start-the-journey {
    position: relative;
    text-align: center;
}

.start-the-journey-text:before {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    content: '';
    background-color: var(--default-red);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.start-the-journey-text {
    position: relative;
    display: flex;
    z-index: 1;
    text-align: center;
    line-height: 100%;
    margin: auto 0px;
    width: 260px;
    height: 40px;
    cursor: pointer;
}

.start-the-journey-text h3 {
    line-height: 40px;
    text-align: center;
    padding: 1px;
    font-size: 20px !important;
    position: relative;
    z-index: 1;
    margin-right: 15px;
}

#lottie-box-home-down-arrow {
    position: relative;
    z-index: 0;
}

@media (min-width: 768px) {
    .hero-banner-box:before {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
        background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
    }
    .hero-banner-box {
        width: 100%;
        height: 100vh;
        background-image: url('../assets/bannerBackground/background.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0% 100%;
        position: relative;
    }
    .fade-moving-left {
        animation-name: fadeLeft;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 2.5s;
    }
    .hero-banner-content-background-blink:before {
        width: 100%;
        height: 100%;
        background-size: 15%, 15%;
        background-position: 90% 100%;
        background-repeat: no-repeat;
        animation-name: blinkBackground;
        animation-iteration-count: infinite;
        animation-duration: 20s;
        opacity: .9;
        content: "";
        position: absolute;
        z-index: 0;
    }
    .hero-banner-content {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .hero-banner-content-text-box {
        width: 70%;
        height: 50%;
        padding: 60px 30px;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        z-index: 1;
        text-align: center;
    }
    .hero-banner-content-text-box h1,
    .hero-banner-content-text-box h3 {
        color: #fff;
        font-weight: 900;
    }
    .hero-banner-content-text-box h1 {
        font-size: 50px;
        text-transform: uppercase;
    }
    .hero-banner-content-text-box h3 {
        font-size: 35px;
    }
    .hero-banner-link {
        margin-top: 100px;
    }
    .start-the-journey {
        width: 50%;
        position: relative;
        text-align: center;
    }
    .start-the-journey-text:before {
        overflow: hidden;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--default-red);
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }
    .start-the-journey-text {
        position: relative;
        display: flex;
        z-index: 1;
        text-align: center;
        line-height: 100%;
        margin: auto 0px;
        width: 400px;
        height: 60px;
    }
    .start-the-journey-text h3 {
        line-height: 60px;
        text-align: center;
        padding: 1px;
        font-size: 30px !important;
        position: relative;
        z-index: 1;
        margin-right: 15px;
    }
    #lottie-box-home-down-arrow {
        position: relative;
        z-index: 0;
    }
}

@media (min-width: 992px) {
    .hero-banner-content-text-box h1 {
        font-size: 100px;
        text-transform: uppercase;
    }
}