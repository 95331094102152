        .custom-card {
            width: 100%;
            height: auto;
            padding: 30px;
            margin-bottom: 100px;
            box-shadow: 0px 0px 10px -5px #333;
            border-radius: 10px;
            border: 0;
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
            position: relative;
            overflow: hidden;
        }
        
        .custom-card::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 50%;
            top: 0;
            left: 0;
            border: 0;
        }
        
        .custom-card-right-side {
            width: 100%;
            height: auto;
            padding: 30px;
            margin-bottom: 100px;
            box-shadow: 0px 0px 10px -5px #333;
            border-radius: 10px;
            border: 0;
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
            position: relative;
            overflow: hidden;
        }
        
        .custom-card-right-side::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 50%;
            top: 0;
            left: 0;
            border: 0;
        }
        
        .thank-you-section h1 {
            line-height: 400px;
            color: var(--high-blue);
            font-size: 60px;
            line-height: 70px;
            font-weight: 900;
            animation-name: _blink;
            animation-duration: 4s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
        
        .home-pre-sections-title {
            text-align: center;
            padding: 100px 10px;
            display: block;
        }
        
        .home-pre-sections-title .h1-red {
            color: var(--default-red);
            font-weight: 900;
            text-transform: capitalize;
            font-size: x-large;
        }
        
        .home-pre-sections-title .h1-blue {
            color: var(--default-blue);
            font-weight: 900;
            text-transform: capitalize;
            font-size: x-large;
        }
        
        .custom-sub-card {
            background-color: #fff;
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 5px var(--dark-blue);
            width: 100%;
        }
        
        .custom-sub-card-header {
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, #dc3545 0%, #dc3545 10%, #670426 100%);
            text-align: center;
            padding: 15px;
            border-radius: 8px 8px 0px 0px;
        }
        
        .custom-sub-card-header h3 {
            text-align: center;
            font-weight: 700;
        }
        
        .custom-sub-card-content {
            border: 0;
            border-radius: 10px;
            padding: 5px;
        }
        
        .custom-sub-card-content p {
            color: var(--default-blue);
            text-align: center;
        }
        
        .custom-sub-card-content p b {
            color: var(--default-blue);
            text-align: center;
            font-weight: 700;
        }
        
        @media (min-width: 768px) {
            .custom-card {
                width: 100%;
                padding: 30px;
                margin-bottom: 100px;
                box-shadow: 0px 0px 10px -5px #333;
                border-radius: 10px;
                border: 0;
                background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
                position: relative;
                overflow: hidden;
            }
            .custom-card::before {
                position: absolute;
                content: '';
                width: 30%;
                height: 100%;
                top: 0;
                left: 0;
                border: 0;
            }
            .custom-card-right-side {
                width: 100%;
                padding: 30px;
                margin-bottom: 100px;
                box-shadow: 0px 0px 10px -5px #333;
                border-radius: 10px;
                border: 0;
                background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
                position: relative;
                overflow: hidden;
            }
            .custom-card-right-side::before {
                position: absolute;
                content: '';
                width: 30%;
                height: 100%;
                top: 0;
                left: 70%;
                border: 0;
            }
            .thank-you-section h1 {
                line-height: 400px;
                color: #777;
                font-size: 60px;
                font-weight: 900;
                animation-name: _blink;
                animation-duration: 4s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }
            .home-pre-sections-title {
                text-align: center;
                padding: 150px 10px;
                display: block;
            }
            .home-pre-sections-title .h1-red {
                color: var(--default-red);
                font-weight: 900;
                text-transform: capitalize;
                font-size: 50px;
            }
            .home-pre-sections-title .h1-blue {
                color: var(--default-blue);
                font-weight: 900;
                text-transform: capitalize;
                font-size: 40px;
            }
        }
        
        .hidden {
            transform: scale(0.001);
        }
        
        .thank-you-section {
            height: 400px;
            text-align: center;
            background-image: url('../assets/thank-you-background.svg');
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: 30% 65%;
        }
        
        @keyframes _blink {
            0% {
                color: var(--high-blue);
            }
            50% {
                color: var(--dark-blue);
            }
            100% {
                color: var(--high-blue);
            }
        }
        
        @media (max-width:767px) {
            .custom-card-right-side,
            .custom-car,
            .custom-card-ticket,
            .player-card-section-home-box,
            .report-card-section-home-box {
                border-radius: 0px !important;
            }
            .payment-card-section-home-text-box h1 {
                margin-bottom: 25px;
            }
            #lottie-box-home-player-image {
                margin-top: -80px;
            }
        }