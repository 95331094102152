@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
:root {
    --default-background: linear-gradient(15deg, rgb(247, 165, 165) 0%, rgba(217, 43, 76, 1) 20%, rgba(217, 43, 76, 1) 70%, rgb(247, 165, 165) 80%, #064470 100%);
    --default-blue: #064470;
    --default-blue-shadow: #00010275;
    --default-blue-shadow-mobile: #0001029f;
    --dark-blue: #0e1336;
    --high-blue: #549dd6;
    --default-secondary: #8499a8;
    --high-secondary: #bac4cc;
    --default-red: #dc3545;
}
body * {
    font-family: 'Roboto', sans-serif;
    color: var(--default-secondary);
}

#root {
    max-width: 100vw;
    overflow: hidden;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: var(--default-blue);
    border: var(--default-blue);
    outline: 0px !important;
}

.btn-primary * {
    color: #fff;
}

.btn-blue,
.btn-blue:hover,
.btn-blue:active,
.btn-blue:visited,
.btn-blue:focus {
    background-color: var(--dark-blue);
    border: var(--dark-blue);
    outline: 0px !important;
}

.btn-blue {
    color: #fff;
    font-weight: 700;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:visited,
.btn-danger:focus {
    background-color: var(--default-red);
    border: var(--default-red);
    outline: 0px !important;
}

.btn-danger {
    color: #fff;
}

.btn-light,
.btn-light:hover,
.btn-light:active,
.btn-light:visited,
.btn-light:focus {
    background-color: #f0c790;
    border-color: #f0c790;
    outline: 0px !important;
    box-shadow: 0px 0px 10px 1px var(--dark-blue);
    -webkit-transform: scale(1, 1.2);
            transform: scale(1, 1.2);
}

.btn-light {
    color: var(--dark-blue);
    font-weight: 900;
}

.btn-light:hover {
    color: var(--default-blue);
    font-weight: 600;
    transition: all .5s ease-in-out;
    -webkit-transform: scale(1.01, 1.3);
            transform: scale(1.01, 1.3);
}
@-webkit-keyframes blinkBackground {
    0% {
        background-image: url(/static/media/1.1093376e.svg);
        -webkit-transform: scale(1);
                transform: scale(1);
        background-position: 90% 100%;
    }
    20% {
        background-image: url(/static/media/1.1093376e.svg);
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    25% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/2.16dae060.svg);
        background-position: 0% 100%;
        opacity: .9;
    }
    45% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/2.16dae060.svg);
        opacity: .1;
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/3.4d3e661e.svg);
        background-position: 90% 0%;
        opacity: .1;
    }
    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/3.4d3e661e.svg);
        background-position: 0% 10%;
        opacity: .9;
    }
    75% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/4.b9dccc34.svg);
        opacity: .0;
    }
    95% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/4.b9dccc34.svg);
        opacity: .9;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/1.1093376e.svg);
    }
}

@keyframes blinkBackground {
    0% {
        background-image: url(/static/media/1.1093376e.svg);
        -webkit-transform: scale(1);
                transform: scale(1);
        background-position: 90% 100%;
    }
    20% {
        background-image: url(/static/media/1.1093376e.svg);
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
    25% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/2.16dae060.svg);
        background-position: 0% 100%;
        opacity: .9;
    }
    45% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/2.16dae060.svg);
        opacity: .1;
    }
    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/3.4d3e661e.svg);
        background-position: 90% 0%;
        opacity: .1;
    }
    70% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/3.4d3e661e.svg);
        background-position: 0% 10%;
        opacity: .9;
    }
    75% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/4.b9dccc34.svg);
        opacity: .0;
    }
    95% {
        -webkit-transform: scale(.9);
                transform: scale(.9);
        background-image: url(/static/media/4.b9dccc34.svg);
        opacity: .9;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        background-image: url(/static/media/1.1093376e.svg);
    }
}

.hero-banner-box:before {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: -1;
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
}

.hero-banner-box {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/background.b2391fcd.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    position: relative;
}

.hero-banner-content-background-blink:before {
    width: 100%;
    height: 100%;
    background-size: 60%, 60%;
    background-position: 50%;
    background-repeat: no-repeat;
    -webkit-animation-name: blinkBackground;
            animation-name: blinkBackground;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
    content: "";
    position: absolute;
    z-index: 0;
    opacity: 1;
}

.hero-banner-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.hero-banner-content-text-box {
    padding: 60px 30px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
}

.hero-banner-content-text-box-wrap {
    position: relative;
    z-index: 2;
    text-align: center;
}

.hero-banner-content-text-box h1,
.hero-banner-content-text-box h3 {
    color: #fff;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.hero-banner-content-text-box h1 {
    font-size: 40px;
    text-transform: uppercase;
}

.hero-banner-link a {
    text-decoration: none;
}

.start-the-journey {
    position: relative;
    text-align: center;
}

.start-the-journey-text:before {
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    content: '';
    background-color: var(--default-red);
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.start-the-journey-text {
    position: relative;
    display: -webkit-flex;
    display: flex;
    z-index: 1;
    text-align: center;
    line-height: 100%;
    margin: auto 0px;
    width: 260px;
    height: 40px;
    cursor: pointer;
}

.start-the-journey-text h3 {
    line-height: 40px;
    text-align: center;
    padding: 1px;
    font-size: 20px !important;
    position: relative;
    z-index: 1;
    margin-right: 15px;
}

#lottie-box-home-down-arrow {
    position: relative;
    z-index: 0;
}

@media (min-width: 768px) {
    .hero-banner-box:before {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
        background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
    }
    .hero-banner-box {
        width: 100%;
        height: 100vh;
        background-image: url(/static/media/background.b2391fcd.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0% 100%;
        position: relative;
    }
    .fade-moving-left {
        -webkit-animation-name: fadeLeft;
                animation-name: fadeLeft;
        -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-animation-duration: 2.5s;
                animation-duration: 2.5s;
    }
    .hero-banner-content-background-blink:before {
        width: 100%;
        height: 100%;
        background-size: 15%, 15%;
        background-position: 90% 100%;
        background-repeat: no-repeat;
        -webkit-animation-name: blinkBackground;
                animation-name: blinkBackground;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-duration: 20s;
                animation-duration: 20s;
        opacity: .9;
        content: "";
        position: absolute;
        z-index: 0;
    }
    .hero-banner-content {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .hero-banner-content-text-box {
        width: 70%;
        height: 50%;
        padding: 60px 30px;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -70%);
                transform: translate(-50%, -70%);
        z-index: 1;
        text-align: center;
    }
    .hero-banner-content-text-box h1,
    .hero-banner-content-text-box h3 {
        color: #fff;
        font-weight: 900;
    }
    .hero-banner-content-text-box h1 {
        font-size: 50px;
        text-transform: uppercase;
    }
    .hero-banner-content-text-box h3 {
        font-size: 35px;
    }
    .hero-banner-link {
        margin-top: 100px;
    }
    .start-the-journey {
        width: 50%;
        position: relative;
        text-align: center;
    }
    .start-the-journey-text:before {
        overflow: hidden;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--default-red);
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }
    .start-the-journey-text {
        position: relative;
        display: -webkit-flex;
        display: flex;
        z-index: 1;
        text-align: center;
        line-height: 100%;
        margin: auto 0px;
        width: 400px;
        height: 60px;
    }
    .start-the-journey-text h3 {
        line-height: 60px;
        text-align: center;
        padding: 1px;
        font-size: 30px !important;
        position: relative;
        z-index: 1;
        margin-right: 15px;
    }
    #lottie-box-home-down-arrow {
        position: relative;
        z-index: 0;
    }
}

@media (min-width: 992px) {
    .hero-banner-content-text-box h1 {
        font-size: 100px;
        text-transform: uppercase;
    }
}
.header-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    width: 100%;
    height: 8vh;
    min-height: 70px;
}

.header-navbar {
    height: 100%;
    width: 100%;
}

.header-navbar-logo {
    height: 100%;
    width: 50%;
    position: relative;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.header-navbar-logo img {
    width: 80%;
    margin-left: 10%;
}

@media (min-width: 768px) {
    .header-navbar-logo {
        height: 110%;
        width: 15%;
        min-width: 200px;
        max-height: 90px;
        position: relative;
        background-color: #fff;
        padding: 10px 25px;
    }
    .header-navbar-logo img {
        position: absolute;
        width: 80%;
        -webkit-transform: translate(0%, 0%);
                transform: translate(0%, 0%);
        margin: auto;
    }
}
.footer {
    background-color: var(--dark-blue);
    width: 100%;
    min-height: 30vh;
    color: var(--default-secondary);
    text-align: center;
    padding: 50px;
}

.footer a {
    text-decoration: none;
}

.footer a:hover {
    color: var(--high-blue);
}
.tom-bola-partners-wrap {
    position: relative;
    min-height: 120px;
}

.tom-bola-partners-hero-banner-section-hidden {
    opacity: 0 !important;
    transition: opacity 1s ease-in-out;
}

.tom-bola-partners-hero-banner-section-hidden * {
    pointer-events: none !important;
}

.tom-bola-partners-hero-banner-section {
    width: 100%;
    opacity: 1;
    position: absolute;
    top: 0%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: opacity 1s ease-in-out;
    text-align: center;
    z-index: 999;
}

.tom-bola-partners-hero-banner-section h1 {
    color: #fff;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.tom-bola-partners-hero-banner-section img {
    cursor: pointer;
}

@media (max-width:768px) {
    .tom-bola-partners-wrap {
        min-height: 120px;
    }
    .tom-bola-partners-hero-banner-section {
        -webkit-transform: translate(-50%, -60%);
                transform: translate(-50%, -60%);
    }
}

@media (max-width:420px) {
    .tom-bola-partners-wrap {
        min-height: 100px;
    }
    .tom-bola-partners-hero-banner-section {
        -webkit-transform: translate(-50%, -80%);
                transform: translate(-50%, -80%);
    }
}

@media (max-width:320px) {
    .tom-bola-partners-wrap {
        min-height: 150px;
    }
    .tom-bola-partners-hero-banner-section {
        -webkit-transform: translate(-50%, -60%);
                transform: translate(-50%, -60%);
    }
}
.full-tape-box {
    width: 100%;
    min-height: 230px;
    background-color: #fff;
    background-image: url(/static/media/bannerBackgroundImage2.f7bb460c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-shadow: inset -10px -30px 10px -20px rgb(255, 255, 255);
}

.full-left-tape-img-box img {
    padding: 20%;
    width: 100%;
}

.full-left-tape-img-box content {
    padding: 20%;
    width: 100%;
}

.full-left-tape-text-box {
    position: relative;
}

.full-left-tape-text-box * {
    color: #333;
    text-align: center;
}

.full-left-tape-text-box {
    padding: 0px;
}

.full-left-tape-text-box div {
    width: 100%;
}

.full-left-tape-text-box h2 {
    font-size: 18px;
    font-weight: 500;
}

.fade-moving-right-to-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-animation-name: fade-moving-right-to-left;
            animation-name: fade-moving-right-to-left;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

@media (min-width: 768px) {
    .full-tape-box {
        width: 100%;
        min-height: 350px;
        background-color: #fff;
        background-image: url(/static/media/bannerBackgroundImage2.f7bb460c.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-shadow: inset -10px -30px 10px -20px rgb(255, 255, 255);
    }
    .full-left-tape-img-box img {
        padding: 20%;
        width: 100%;
    }
    .full-left-tape-img-box content {
        padding: 20%;
        width: 100%;
    }
    .full-left-tape-text-box {
        position: relative;
    }
    .full-left-tape-text-box * {
        color: #333;
        text-align: center;
    }
    .full-left-tape-text-box div {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .full-left-tape-text-box h1 {
        font-size: 60px;
        text-transform: uppercase;
        font-weight: 700;
    }
}
.player-card-section-home-text-box-wrap {
    margin-top: -15%;
}

.player-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.player-card-section-home-text-box p {
    font-size: 18px;
}

.player-card-section-home-text-box h1 {
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 35px;
    margin-bottom: 30px;
}

.player-card-section-home-text-box {
    width: 100%;
}

@media (min-width: 768px) {
    .player-card-section-home-text-box-wrap {
        margin-top: 0%;
    }
    .player-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        padding-bottom: 10%;
    }
    .player-card-section-home-box-row::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url(/static/media/playerSectionBackGround.4a8505c2.svg);
        background-size: 28% 28%;
        background-position: 100% 105%;
        background-repeat: no-repeat;
    }
    .player-card-section-home-text-box * {
        color: #fff;
        text-align: left;
    }
    .player-card-section-home-text-box p {
        width: 100%;
        height: 100%;
        font-size: 22px;
    }
    .player-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 600 !important;
        margin-bottom: 50px;
        padding: 15px;
        margin-left: -15px;
        border-radius: 10px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
    .player-card-section-home-text-box {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

@media (min-width: 1200px) {
    .player-card-section-home-text-box h1 {
        font-size: 70px;
        font-weight: 900;
        margin-bottom: 70px;
    }
    .player-card-section-home-text-box p {
        font-size: 22px;
    }
}
.payment-card-section-home-text-box-wrap {
    margin-top: 0%;
}

.payment-card-section-home-box-row {
    height: 100%;
    content: '';
    background-size: 60% 60%;
    background-position: 130% 10%;
    background-repeat: no-repeat;
}

.payment-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.payment-card-section-home-text-box p {
    font-size: 18px;
    text-align: center;
}

.payment-card-section-home-text-box h1 {
    font-weight: 700;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 35px;
}

.payment-card-section-home-text-box {
    width: 100%;
}

@media (min-width: 768px) {
    .payment-card-section-home-text-box-wrap {
        margin-top: 0%;
    }
    .payment-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        padding-bottom: 10%;
    }
    .payment-card-section-home-box-row::before {
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(/static/media/paymentSectionBackGround.e9dd8202.svg);
        background-size: 20% 20%;
        background-position: 0% 100%;
        background-repeat: no-repeat;
        opacity: 1;
    }
    .payment-card-section-home-text-box * {
        text-align: left;
    }
    .payment-card-section-home-text-box p {
        font-size: 22px;
        text-align: left;
    }
    .payment-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 900;
        margin-bottom: 50px;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
    }
    .payment-card-section-home-text-box {
        width: 100%;
        position: relative;
        margin-bottom: 60px;
    }
}

@media (min-width: 1200px) {
    .payment-card-section-home-text-box p {
        font-size: 22px;
    }
    .payment-card-section-home-text-box h1 {
        font-size: 70px;
        margin-bottom: 70px;
    }
}
        .custom-card {
            width: 100%;
            height: auto;
            padding: 30px;
            margin-bottom: 100px;
            box-shadow: 0px 0px 10px -5px #333;
            border-radius: 10px;
            border: 0;
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
            position: relative;
            overflow: hidden;
        }
        
        .custom-card::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 50%;
            top: 0;
            left: 0;
            border: 0;
        }
        
        .custom-card-right-side {
            width: 100%;
            height: auto;
            padding: 30px;
            margin-bottom: 100px;
            box-shadow: 0px 0px 10px -5px #333;
            border-radius: 10px;
            border: 0;
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
            position: relative;
            overflow: hidden;
        }
        
        .custom-card-right-side::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 50%;
            top: 0;
            left: 0;
            border: 0;
        }
        
        .thank-you-section h1 {
            line-height: 400px;
            color: var(--high-blue);
            font-size: 60px;
            line-height: 70px;
            font-weight: 900;
            -webkit-animation-name: _blink;
                    animation-name: _blink;
            -webkit-animation-duration: 4s;
                    animation-duration: 4s;
            -webkit-animation-timing-function: linear;
                    animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
        }
        
        .home-pre-sections-title {
            text-align: center;
            padding: 100px 10px;
            display: block;
        }
        
        .home-pre-sections-title .h1-red {
            color: var(--default-red);
            font-weight: 900;
            text-transform: capitalize;
            font-size: x-large;
        }
        
        .home-pre-sections-title .h1-blue {
            color: var(--default-blue);
            font-weight: 900;
            text-transform: capitalize;
            font-size: x-large;
        }
        
        .custom-sub-card {
            background-color: #fff;
            border: 0;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 5px var(--dark-blue);
            width: 100%;
        }
        
        .custom-sub-card-header {
            background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, #dc3545 0%, #dc3545 10%, #670426 100%);
            text-align: center;
            padding: 15px;
            border-radius: 8px 8px 0px 0px;
        }
        
        .custom-sub-card-header h3 {
            text-align: center;
            font-weight: 700;
        }
        
        .custom-sub-card-content {
            border: 0;
            border-radius: 10px;
            padding: 5px;
        }
        
        .custom-sub-card-content p {
            color: var(--default-blue);
            text-align: center;
        }
        
        .custom-sub-card-content p b {
            color: var(--default-blue);
            text-align: center;
            font-weight: 700;
        }
        
        @media (min-width: 768px) {
            .custom-card {
                width: 100%;
                padding: 30px;
                margin-bottom: 100px;
                box-shadow: 0px 0px 10px -5px #333;
                border-radius: 10px;
                border: 0;
                background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
                position: relative;
                overflow: hidden;
            }
            .custom-card::before {
                position: absolute;
                content: '';
                width: 30%;
                height: 100%;
                top: 0;
                left: 0;
                border: 0;
            }
            .custom-card-right-side {
                width: 100%;
                padding: 30px;
                margin-bottom: 100px;
                box-shadow: 0px 0px 10px -5px #333;
                border-radius: 10px;
                border: 0;
                background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
                position: relative;
                overflow: hidden;
            }
            .custom-card-right-side::before {
                position: absolute;
                content: '';
                width: 30%;
                height: 100%;
                top: 0;
                left: 70%;
                border: 0;
            }
            .thank-you-section h1 {
                line-height: 400px;
                color: #777;
                font-size: 60px;
                font-weight: 900;
                -webkit-animation-name: _blink;
                        animation-name: _blink;
                -webkit-animation-duration: 4s;
                        animation-duration: 4s;
                -webkit-animation-timing-function: linear;
                        animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                        animation-iteration-count: infinite;
            }
            .home-pre-sections-title {
                text-align: center;
                padding: 150px 10px;
                display: block;
            }
            .home-pre-sections-title .h1-red {
                color: var(--default-red);
                font-weight: 900;
                text-transform: capitalize;
                font-size: 50px;
            }
            .home-pre-sections-title .h1-blue {
                color: var(--default-blue);
                font-weight: 900;
                text-transform: capitalize;
                font-size: 40px;
            }
        }
        
        .hidden {
            -webkit-transform: scale(0.001);
                    transform: scale(0.001);
        }
        
        .thank-you-section {
            height: 400px;
            text-align: center;
            background-image: url(/static/media/thank-you-background.703d7cea.svg);
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: 30% 65%;
        }
        
        @-webkit-keyframes _blink {
            0% {
                color: var(--high-blue);
            }
            50% {
                color: var(--dark-blue);
            }
            100% {
                color: var(--high-blue);
            }
        }
        
        @keyframes _blink {
            0% {
                color: var(--high-blue);
            }
            50% {
                color: var(--dark-blue);
            }
            100% {
                color: var(--high-blue);
            }
        }
        
        @media (max-width:767px) {
            .custom-card-right-side,
            .custom-car,
            .custom-card-ticket,
            .player-card-section-home-box,
            .report-card-section-home-box {
                border-radius: 0px !important;
            }
            .payment-card-section-home-text-box h1 {
                margin-bottom: 25px;
            }
            #lottie-box-home-player-image {
                margin-top: -80px;
            }
        }
.custom-card-ticket {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.ticket-section-card-header {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, var(--dark-blue) 100%);
    padding: 30px;
    min-height: 250px;
    position: relative;
}

.ticket-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.ticket-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.ticket-section-text-box {
    padding: 20px;
}

.ticket-section-text-box p,
.ticket-section-text-box b {
    width: 100%;
    color: var(--default-blue);
    text-shadow: 0px 2px 2px rgba(231, 227, 227, 0.1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-align: center;
    font-size: 18px;
}

.ticket-section-text-box b {
    color: var(--default-red);
}

@media (min-width: 768px) {
    .ticket-section-text-box {
        padding: 30px;
        background-image: url(/static/media/tickets-section-background.c880017c.svg);
        background-size: 35% 45%;
        background-position: 100%, 120%;
        background-repeat: no-repeat;
    }
    .ticket-section-text-box p,
    .ticket-section-text-box b {
        width: 70%;
        color: var(--default-blue);
        text-align: center;
        font-size: 22px;
    }
    .ticket-section-text-box b {
        color: var(--default-red);
    }
    .ticket-section-card-header h1 {
        font-size: 60px;
    }
}
.extra-section-header {
    padding: 30px;
    text-align: center;
}

.extra-section-header h1 {
    color: var(--default-blue);
    font-weight: 700;
    font-size: 40px;
}

.extra-section-container {
    padding: 100px 10px;
}

.extra-section-body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.extra-section-body p,
.extra-section-body b {
    color: var(--default-blue);
    font-size: 18px;
    text-align: center;
}

.extra-section-body h1 {
    color: var(--default-blue);
    font-weight: 700;
    font-size: 22px;
    margin-top: 80px;
    text-align: center;
}

@media (min-width: 768px) {
    .extra-section-body p,
    .extra-section-body b {
        color: var(--default-blue);
        font-size: 22px;
        text-align: left;
    }
    .extra-section-body h1 {
        color: var(--default-blue);
        font-weight: 900;
        font-size: 60px;
        text-align: left;
        margin: 0;
        margin-bottom: 50px;
    }
}

@media (min-width: 1200px) {
    .extra-section-body p,
    .extra-section-body b {
        color: var(--default-blue);
        font-size: 22px;
        text-align: left;
    }
    .extra-section-body h1 {
        color: var(--default-blue);
        font-weight: 900;
        font-size: 80px;
        text-align: left;
        margin: 0;
        margin-bottom: 80px;
    }
}
.selector-lucky-wheel {
    font-size: 60px;
    color: var(--default-blue);
    position: absolute;
    text-align: center;
    top: calc(200 / 25 * 1px + 5px);
    top: calc(var(--size, 200) / 25 * 1px + 5px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 998;
    text-shadow: 1px 1px 5px #000000;
}

.pie {
    border-radius: 100%;
    height: calc(200 * 1px);
    height: calc(var(--size, 200) * 1px);
    overflow: hidden;
    position: relative;
    width: calc(200 * 1px);
    width: calc(var(--size, 200) * 1px);
    border: solid calc(200 / 25 * 1px) #fff !important;
    border: solid calc(var(--size, 200) / 25 * 1px) #fff !important;
}

.pie__segment {
    --a: calc(var(--over50, 0) * -100%);
    --b: calc((1 + var(--over50, 0)) * 100%);
    --degrees: calc((var(--offset, 0) / 100) * 360);
    -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    height: 100%;
    position: absolute;
    -webkit-transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
            transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    width: 100%;
    z-index: calc(1 + var(--over50));
}

.pie__segment:after,
.pie__segment:before {
    background-color: var(--bgc);
    background-image: var(--bg);
    background-position: 8% 4%;
    background-size: 20% 20%;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
}

.pie__segment:before {
    --degrees: calc((var(--value, 45) / 100) * 360);
    -webkit-transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
            transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
}

.pie__segment:after {
    opacity: 0;
    opacity: var(--over50, 0);
}

.box-btn-spin-lucky-wheel {
    margin-top: 40px;
    width: 100%;
    position: absolute;
    bottom: -60px;
}

.btn-spin-lucky-wheel {
    -webkit-animation: shake;
            animation: shake;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.btn-spin-lucky-wheel:disabled {
    -webkit-animation: none;
            animation: none;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
}

.lucky-wheel-winner-text {
    text-align: center;
    padding: 15px;
    content: '';
    min-height: 60px;
}

.lucky-wheel-winner-text span {
    color: var(--default-blue);
    font-size: 22px;
    font-weight: 700;
}

@-webkit-keyframes shake {
    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
                transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
                transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
                transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
                transform: translate3d(4px, 0, 0);
    }
}

@keyframes shake {
    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
                transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
                transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
                transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
                transform: translate3d(4px, 0, 0);
    }
}
.report-card-section-home-box-row {
    width: 100%;
    height: 100%;
    content: '';
    background-size: 60% 60%;
    background-position: 130% 10%;
    background-repeat: no-repeat;
}

#report-card-section-home {
    margin-top: 100px;
}

.report-card-section-home-text-box * {
    color: #fff;
    text-align: center;
}

.report-card-section-home-text-box p {
    font-size: 18px;
}

.report-card-section-home-text-box h1 {
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

@media (max-width: 420px) {
    .report-card-section-home-text-box {
        width: 100%;
        margin-top: -20% !important;
    }
    .report-card-section-home-text-box h1 {
        margin-bottom: 30px;
    }
    #lottie-box-home-report-image {
        margin-top: -30%;
    }
}

#lottie-box-home-report-image {
    width: 140%;
    margin-left: -20%;
}

@media(max-width:767px) {
    #lottie-box-home-report-image {
        margin-top: -30%;
    }
    .report-card .custom-card {
        width: 100%;
        height: auto;
        padding: 30px;
        box-shadow: 0px 0px 10px 0px #333;
        border-radius: 10px;
        border: 0;
        position: relative;
        overflow: hidden;
    }
    .report-card .custom-card::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 35%;
        top: 0;
        left: 0;
        border: 0;
    }
}

@media (min-width: 768px) {
    .report-card-section-home-box-row {
        width: 100%;
        height: 100%;
        content: '';
        background-image: url(/static/media/reportSectionBackGround.8dc7674a.svg);
        background-size: 20% 20%;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        padding-bottom: 12%;
    }
    .report-card-section-home-text-box * {
        text-align: left;
    }
    .report-card-section-home-text-box p {
        font-size: 22px;
    }
    .report-card-section-home-text-box h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 50px;
    }
    .report-card-section-home-text-box {
        width: 100%;
        position: relative;
    }
    #lottie-box-home-report-image {
        width: 100%;
        margin-left: -15%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .report-card-section-home-box-row {
        display: -webkit-flex;
        display: flex;
    }
}

@media (min-width: 1200px) {
    .report-card-section-home-text-box h1 {
        font-size: 60px;
        font-weight: 900;
        margin-bottom: 70px;
    }
}
.custom-card-compliance {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.compliance-section-card-header {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    padding: 40px;
    height: 100px;
}

.compliance-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.compliance-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.compliance-section-text-box {
    padding: 20px;
    /* background-image: url('../assets/compliances-section-background.svg'); */
    background-size: 50% 50%;
    background-position: 120%, 120%;
    background-repeat: no-repeat;
    display: block;
}

.compliance-image-wrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.compliance-image-1,
.compliance-image-2,
.compliance-image-3 {
    content: '';
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 3.5s;
            animation-duration: 3.5s;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.compliance-image-2 {
    background-image: url(/static/media/ukgc-logo.a7313bfd.png);
    background-size: auto 100%;
}

.compliance-image-3 {
    background-image: url(/static/media/eugdpr-compliant.4b090849.png);
    background-size: 100% auto;
}

.compliance-image-1,
.compliance-image-4 {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    margin-bottom: 15px;
}

.compliance-image-1:after,
.compliance-image-4:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 0%;
    background-color: transparent;
    background-image: url(/static/media/spm_logo.ffee6014.png);
}

.compliance-image-1:after {
    background-image: url("/static/media/RNG certified.ae203611.png");
}

.compliance-image-1::before,
.compliance-image-4::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(0deg, var(--dark-blue) 0%, var(--default-blue) 50%, var(--dark-blue) 100%);
}

.compliance-image-1::before {
    background: #c3c3c3
}

@-webkit-keyframes shakeUp {
    10%,
    90% {
        -webkit-transform: translate3d(0, -1px, 0);
                transform: translate3d(0, -1px, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(0, 2px, 0);
                transform: translate3d(0, 2px, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(0, 4px, 0);
                transform: translate3d(0, 4px, 0);
    }
}

@keyframes shakeUp {
    10%,
    90% {
        -webkit-transform: translate3d(0, -1px, 0);
                transform: translate3d(0, -1px, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(0, 2px, 0);
                transform: translate3d(0, 2px, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(0, 4px, 0);
                transform: translate3d(0, 4px, 0);
    }
}

.compliance-section-text-box p {
    width: 100%;
    color: var(--default-blue);
    text-align: center;
    font-size: 18px;
}

.compliance-acronyms * {
    text-align: center;
    list-style: none;
}

@media (min-width: 768px) {
    .compliance-section-text-box {
        padding: 30px;
        background-image: url(/static/media/compliance-section-background.0cea4a71.svg);
        background-size: 28% 28%;
        background-position: 100% 100%;
        padding-bottom: 10%;
    }
    .compliance-section-card-header {
        background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
        padding: 30px;
        height: 120px;
    }
    .compliance-section-text-box {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .compliance-section-text-box p {
        width: 70%;
        margin-left: 5%;
        color: var(--default-blue);
        text-align: left;
        font-size: 22px;
    }
    .compliance-section-card-header h1 {
        font-size: 60px;
    }
}
.custom-card-contact {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    box-shadow: 0px 0px 10px 0px #333;
    border-radius: 10px;
    border: 0;
    overflow: hidden;
    padding-bottom: 30px;
}

.contact-section-card-header {
    padding: 0px;
}

.contact-form-body-wrap {
    background: radial-gradient(circle, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    margin-bottom: 100px;
    box-shadow: 0px 10px 20px 2px #333;
    padding-bottom: 100px;
}

.contact-section-card-header h1 {
    color: #fff;
    text-align: center;
    font-weight: 900;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.contact-card-animation {
    position: absolute;
    top: 0%;
    left: calc(50% - 150px);
    content: '';
    width: 300px;
    height: 300px;
}

.contact-section-text-box {
    padding: 20px;
    background-image: url(/static/media/contact-section-background.262e8ef3.svg);
    background-size: 50% auto;
    background-position: 100%, 120%;
    background-repeat: no-repeat;
}

.contact-section-text-box p,
.contact-section-text-box h3 {
    color: #fff;
    text-align: center;
}

.contact-section-text-box p {
    width: 100%;
    font-size: 18px;
}

.contact-section-text-box .input-group-prepend * {
    height: 100%;
}

.contact-section-text-box .btn-block {
    margin-top: 15px;
    width: 100%;
}

.contact-section-text-box .fa {
    width: 24px;
}

.contact-section-text-box input,
.contact-section-text-box textarea {
    border-radius: 0px 5px 5px 0px !important;
    background-color: rgba(0, 0, 0, 0.4);
}

.contact-section-text-box textarea {
    border-radius: 5px !important;
}

@media (min-width: 768px) {
    .contact-section-text-box {
        padding: 30px;
    }
    .contact-section-card-header {
        padding-top: 30px;
    }
    .contact-section-text-box p {
        width: 70%;
        margin-left: 15%;
        text-align: center;
        font-size: 22px;
    }
    .contact-section-card-header h1 {
        font-size: 60px;
    }
}
.word-generator-box {
    width: calc( 100%);
    border-radius: 10px;
    overflow: hidden;
    background-image: url(/static/media/word-generaror-background.dd1e4284.svg);
    background-size: 100% 100%;
    background-position: 0%;
    background-repeat: no-repeat;
    margin-bottom: -30px;
    position: relative;
}

.word-generator-box:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: -1;
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
}

.word-generator-header-section {
    height: 300px;
    position: relative;
}

.word-generator-header-section div {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.word-generator-header-section div * {
    color: var(--default-blue);
    text-shadow: 1px 1px 0#fff, -1px 1px 0#fff, 1px -1px 0#fff, -1px -1px 0#fff, 0px 1px 0#fff, 0px -1px 0#fff, -1px 0px 0#fff, 1px 0px 0#fff, 2px 2px 0#fff, -2px 2px 0#fff, 2px -2px 0#fff, -2px -2px 0#fff, 0px 2px 0#fff, 0px -2px 0#fff, -2px 0px 0#fff, 2px 0px 0#fff, 1px 2px 0#fff, -1px 2px 0#fff, 1px -2px 0#fff, -1px -2px 0#fff, 2px 1px 0#fff, -2px 1px 0#fff, 2px -1px 0#fff, -2px -1px 0#fff;
}

.word-generator-body-reel-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    padding: 20px 20px;
}

.reel-box {
    width: 18%;
    margin: 1%;
    height: 130px;
}

.word-generator-button-container {
    width: 100%;
    height: 200px;
    position: relative;
    margin-top: 60px;
}

.word-generator-play-button {
    width: 160px;
    height: 160px;
    margin-left: calc(50% - 100px);
    border-radius: 50% 50%;
    background: radial-gradient( #e6daca 0%, #e9bc80 100%);
    -webkit-animation-name: shakeUp;
            animation-name: shakeUp;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    box-shadow: 0px 0px 30px 15px var(--dark-blue);
}

.word-generator-text-button {
    width: 100%;
    line-height: 160px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

:root {
    --spin-text-shadow: rgb(13 24 60 / 2%);
}

.word-generator-text-button span {
    cursor: pointer;
    text-align: center;
    color: var(--default-red);
    font-size: 40px;
    text-transform: capitalize;
    font-weight: 900;
    text-shadow: 1px 1px 0 rgb(13 24 60 / 2%), -1px 1px 0 rgb(13 24 60 / 2%), 1px -1px 0 rgb(13 24 60 / 2%), -1px -1px 0 rgb(13 24 60 / 2%), 0px 1px 0 rgb(13 24 60 / 2%), 0px -1px 0 rgb(13 24 60 / 2%), -1px 0px 0 rgb(13 24 60 / 2%), 1px 0px 0 rgb(13 24 60 / 2%), 2px 2px 0 rgb(13 24 60 / 2%), -2px 2px 0 rgb(13 24 60 / 2%), 2px -2px 0 rgb(13 24 60 / 2%), -2px -2px 0 rgb(13 24 60 / 2%), 0px 2px 0 rgb(13 24 60 / 2%), 0px -2px 0 rgb(13 24 60 / 2%), -2px 0px 0 rgb(13 24 60 / 2%), 2px 0px 0 rgb(13 24 60 / 2%), 1px 2px 0 rgb(13 24 60 / 2%), -1px 2px 0 rgb(13 24 60 / 2%), 1px -2px 0 rgb(13 24 60 / 2%), -1px -2px 0 rgb(13 24 60 / 2%), 2px 1px 0 rgb(13 24 60 / 2%), -2px 1px 0 rgb(13 24 60 / 2%), 2px -1px 0 rgb(13 24 60 / 2%), -2px -1px 0 rgb(13 24 60 / 2%);
    text-shadow: 1px 1px 0 var(--spin-text-shadow), -1px 1px 0 var(--spin-text-shadow), 1px -1px 0 var(--spin-text-shadow), -1px -1px 0 var(--spin-text-shadow), 0px 1px 0 var(--spin-text-shadow), 0px -1px 0 var(--spin-text-shadow), -1px 0px 0 var(--spin-text-shadow), 1px 0px 0 var(--spin-text-shadow), 2px 2px 0 var(--spin-text-shadow), -2px 2px 0 var(--spin-text-shadow), 2px -2px 0 var(--spin-text-shadow), -2px -2px 0 var(--spin-text-shadow), 0px 2px 0 var(--spin-text-shadow), 0px -2px 0 var(--spin-text-shadow), -2px 0px 0 var(--spin-text-shadow), 2px 0px 0 var(--spin-text-shadow), 1px 2px 0 var(--spin-text-shadow), -1px 2px 0 var(--spin-text-shadow), 1px -2px 0 var(--spin-text-shadow), -1px -2px 0 var(--spin-text-shadow), 2px 1px 0 var(--spin-text-shadow), -2px 1px 0 var(--spin-text-shadow), 2px -1px 0 var(--spin-text-shadow), -2px -1px 0 var(--spin-text-shadow);
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
    50% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg)
    }
}

.ticket-section-word-generator-box {
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    padding: 5%;
}

.word-generator-ticket-frame {
    padding: 15px;
    border-radius: 10px;
    position: relative;
    background-color: #e9bc80;
    background: radial-gradient(circle, #fff 0%, #f7d5a8 10%, #f3ca94 40%, #ecc28b 100%);
    box-shadow: 0px 0px 20px 20px var(--dark-blue);
}

.word-generator-ticket-frame-stripes {
    position: relative;
}

.word-generator-ticket-frame-wrap {
    border: dashed 3px red;
    border-radius: 10px;
    display: -webkit-flex;
    display: flex;
    position: relative;
    z-index: 0;
}

.word-generator-ticket-frame-wrap .word-generator-body-reel-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.word-generator-ticket-number {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: mixed;
            text-orientation: mixed;
    border-right: dashed 3px red;
    padding-right: 10px;
    color: var(--default-blue);
    font-size: 40px;
    font-weight: 900;
    text-align: center;
}

.word-generator-ticket-title {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--default-blue);
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding-top: 20px;
}

.word-generator-ticket-stars {
    position: absolute;
    bottom: 20px;
    left: 0;
    color: var(--default-red);
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
}

.word-generator-ticket-frame::before {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100px;
    height: 100px;
    padding: 4px;
    z-index: 1;
    content: '';
    border-radius: 50% 50%;
    background: linear-gradient(90deg, transparent 0%, transparent 46%, var(--dark-blue) 46%, var(--dark-blue) 100%);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.word-generator-ticket-frame::after {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 100px;
    height: 100px;
    z-index: 0;
    content: '';
    border-radius: 50% 50%;
    background: linear-gradient(270deg, transparent 0%, transparent 48%, var(--dark-blue) 48%, var(--dark-blue) 100%);
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}
:root {
    --reel-slot-width: 200px;
    --reel-slot-height: 130px;
    --noWinners-color_0: var(--default-red);
    --noWinners-color_1: var(--default-red);
    --noWinners-color_2: var(--default-red);
    --noWinners-color_3: var(--default-red);
    --noWinners-color_4: var(--default-red);
    --noWinners-opacity_0: 1;
    --noWinners-opacity_1: 1;
    --noWinners-opacity_2: 1;
    --noWinners-opacity_3: 1;
    --noWinners-opacity_4: 1;
    /* starts whit - later change to  #0e1336*/
}

.reel-main-box {
    width: 200px;
    width: var(--reel-slot-width);
    height: 130px;
    height: var(--reel-slot-height);
    position: absolute;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 2px #503a1d;
    border-radius: 10px;
}

.reel-slot-container {
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
    width: var(--reel-slot-width);
    height: 130px;
    height: var(--reel-slot-height);
    background-color: #fff;
    background: radial-gradient( #f5e3cc 0%, #ecc28b 70%, #af8a59 98%, #000 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
}

.reel-slot-container-shadow {
    width: 100%;
    height: 100%;
    background-position: 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.reel-slot-container-gloss:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAACGCAQAAACLMtxoAAAC7klEQVR42u3dS3LcMAxFUTyI8ifxxPtfZJyKU7b6o04RpLqJbEAa3IMNaPAKAKmWrY93nwwwM+nts8yv/rz3g+AYJFtc2vsxcBy/5Hs/Ao6lmAgFOr0TBmTFZGwRaGRWRCCwER0CIxk7BLKyuBMKBGmhQyArL+KuEp2eWCoxkKmYnQgEGjoERtIf1XcZBAINF1NIpLpDEAh0eqND4EH6VBE7BDbsEEjYIZDoJx0CA0YGRtJfLqYwqDvEWU4g0MTIcOM3EWj0gx0Cg/r6+8kuBAJB+mKpxECv7BAYRIfgphKbeso4a/Vy2/tJcAj+pTJroUOgqTvExWe/0SFQ8+AvinsIWgSCvtVOGXQIVG4qs11pEGj0wtU1BvXYeXExMtD4b+diCg91ZBS/0iEQ5PFtp0QeEOLrb5mTCARfxCkDD3q2cuWUgU29mOKmEneMDCTbUkmHQPATIwMDf1Yp4uUWGmnu/y+DDgHbRgY7BDZunDIwiL9TyVKJTe0Q3FTijmMnEn9SmbQSCDR+pkPgYZ2cYyce3GdOGRjEyKBDoJPfaiAmAoHG6w4x0SHQ8YspJM4PZDCiQyChQyCpx065TQQCYZoZGRiwQyCJQEyMDHSTqTivv7FhZCAhEEh6INgh0NQdYmWpxKYtlYwMdBMjA6PJ3PluC6Ni3/ZFh0CY7FJsIRDoeiC+CQRCBOJEINBNdqVD4CE6xNkWAoHQOwSBQOM1EBc7EQiECMSZQKAjEEjc1joyzgQCIQJBh8CmL5V0CDT3kQFY/DX8K4HAg+xWbLXr3s+Bg1AdGVe77P0cOIgeCDoEGjoEktgh6BDYsFQiiZGx2rr3c+Ag6BBICASSHghGBhoCgf/cit0IBO5u9ctvirpXHRl87os7OgSVih0CI3YIKpV5TQVF9WKHoHIRCGosOgSVi0BQY9EhqFy+98EXx0KHoMYyOgQSOgQ1Fh0CGVfXVCo6BBICgYRAIOGUQQ0ldghkBAIJgUBCIJAQCCT/APGEBr9wYTFaAAAAAElFTkSuQmCC);
    opacity: 0.3;
    background-position: 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.reel-slot-container-content {
    position: absolute;
    width: 100%;
    transition: all 3s ease-in-out;
}

.reel-slot-container-content-item {
    width: 100%;
    height: calc(130px * 0.45);
    height: calc(var(--reel-slot-height) * 0.45);
    text-align: center;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}

.reel-slot-container-content-item-emoji {
    width: 100%;
    height: calc(130px * 0.5);
    height: calc(var(--reel-slot-height) * 0.5);
    background-size: auto 80%;
    background-position: 50%;
    background-repeat: no-repeat;
}

.reel-slot-container-content-item span {
    font-family: 'Anton', cursive;
    color: var(--default-red);
    font-size: 44px;
}

.reel-slot-container-content-item .reel-slot-container-content-item-black {
    font-family: 'Sigmar One', cursive;
    color: #000;
    font-size: 22px;
}

.noWinners_0 span {
    color: var(--default-red);
    color: var(--noWinners-color_0);
    /* opacity: var(--noWinners-opacity_0); */
    transition: color 2s ease-in-out;
}

.noWinners_1 span {
    color: var(--default-red);
    color: var(--noWinners-color_1);
    /* opacity: var(--noWinners-opacity_1); */
    transition: color 2s ease-in-out;
}

.noWinners_2 span {
    color: var(--default-red);
    color: var(--noWinners-color_2);
    /* opacity: var(--noWinners-opacity_1); */
    transition: color 2s ease-in-out;
}

.noWinners_3 {
    color: var(--default-red);
    color: var(--noWinners-color_3);
    opacity: 1;
    opacity: var(--noWinners-opacity_3);
    transition: opacity 1s ease-out;
}

.noWinners_4 {
    color: var(--default-red);
    color: var(--noWinners-color_4);
    opacity: 1;
    opacity: var(--noWinners-opacity_4);
    transition: opacity 1s ease-out;
}
@media (max-width: 767px) {
    .word-generator-mobile-box {
        width: calc( 100%);
        border-radius: 10px;
        overflow: hidden;
        background-color: var(--default-blue);
        background-size: 100% 50%;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        margin-bottom: -30px;
    }
    .word-generator-mobile-header-section {
        height: 100px;
        position: relative;
    }
    .word-generator-mobile-header-section div {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .word-generator-mobile-header-section div * {
        color: var(--default-blue);
        text-shadow: 1px 1px 0#fff, -1px 1px 0#fff, 1px -1px 0#fff, -1px -1px 0#fff, 0px 1px 0#fff, 0px -1px 0#fff, -1px 0px 0#fff, 1px 0px 0#fff, 2px 2px 0#fff, -2px 2px 0#fff, 2px -2px 0#fff, -2px -2px 0#fff, 0px 2px 0#fff, 0px -2px 0#fff, -2px 0px 0#fff, 2px 0px 0#fff, 1px 2px 0#fff, -1px 2px 0#fff, 1px -2px 0#fff, -1px -2px 0#fff, 2px 1px 0#fff, -2px 1px 0#fff, 2px -1px 0#fff, -2px -1px 0#fff;
    }
    .word-generator-mobile-button-container {
        width: 100%;
        height: 150px;
        position: relative;
        margin-top: 60px;
    }
    .word-generator-mobile-play-button {
        width: 100px;
        height: 100px;
        margin-left: calc(50% - 50px);
        border-radius: 50% 50%;
        background: radial-gradient( #e6daca 0%, #e9bc80 100%);
        -webkit-animation-name: shakeUp;
                animation-name: shakeUp;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        box-shadow: 0px 0px 30px 15px var(--dark-blue);
    }
    .word-generator-mobile-text-button {
        width: 100%;
        line-height: 100px;
        text-align: center;
        cursor: pointer;
        z-index: 1;
    }
     :root {
        --spin-text-shadow: rgb(13 24 60 / 2%);
    }
    .word-generator-mobile-text-button span {
        cursor: pointer;
        text-align: center;
        color: var(--default-red);
        font-size: 22px;
        text-transform: capitalize;
        font-weight: 900;
        text-shadow: 1px 1px 0 var(--spin-text-shadow), -1px 1px 0 var(--spin-text-shadow), 1px -1px 0 var(--spin-text-shadow), -1px -1px 0 var(--spin-text-shadow), 0px 1px 0 var(--spin-text-shadow), 0px -1px 0 var(--spin-text-shadow), -1px 0px 0 var(--spin-text-shadow), 1px 0px 0 var(--spin-text-shadow), 2px 2px 0 var(--spin-text-shadow), -2px 2px 0 var(--spin-text-shadow), 2px -2px 0 var(--spin-text-shadow), -2px -2px 0 var(--spin-text-shadow), 0px 2px 0 var(--spin-text-shadow), 0px -2px 0 var(--spin-text-shadow), -2px 0px 0 var(--spin-text-shadow), 2px 0px 0 var(--spin-text-shadow), 1px 2px 0 var(--spin-text-shadow), -1px 2px 0 var(--spin-text-shadow), 1px -2px 0 var(--spin-text-shadow), -1px -2px 0 var(--spin-text-shadow), 2px 1px 0 var(--spin-text-shadow), -2px 1px 0 var(--spin-text-shadow), 2px -1px 0 var(--spin-text-shadow), -2px -1px 0 var(--spin-text-shadow);
    }
    @-webkit-keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg)
        }
        50% {
            -webkit-transform: rotate(360deg);
                    transform: rotate(360deg)
        }
        100% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg)
        }
    }
    @keyframes rotate {
        0% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg)
        }
        50% {
            -webkit-transform: rotate(360deg);
                    transform: rotate(360deg)
        }
        100% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg)
        }
    }
    .word-generator-mobile-body-reel-container {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        position: absolute;
        padding: 0px 10px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .word-generator-mobile-body-reel-container-line-1 {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .word-generator-mobile-body-reel-container-line-2 {
        width: 100%;
        margin-left: 10%;
        display: -webkit-flex;
        display: flex;
        padding: 10px 10px;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
    }
    .reel-box-mobile {
        width: 31.33%;
        margin: 1%;
    }
    .reel-box-mobile-line-2 {
        width: 48%;
        margin: 1%;
    }
    .ticket-section-word-generator-mobile-box {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 10%, rgba(6, 68, 112, 1) 40%, var(--dark-blue) 100%);
    }
    .word-generator-mobile-ticket-frame {
        padding: 15px;
        border-radius: 10px;
        position: relative;
        background-color: #e9bc80;
        background: radial-gradient(circle, #fae1c1 0%, #f7d5a8 10%, #f3ca94 40%, #ecc28b 100%);
        box-shadow: 0px 0px 20px 20px var(--dark-blue);
    }
    .word-generator-mobile-ticket-frame-stripes {
        position: relative;
    }
    .word-generator-mobile-ticket-frame-wrap {
        border: dashed 3px red;
        border-radius: 10px;
        display: -webkit-flex;
        display: flex;
        position: relative;
        z-index: 0;
        height: 100%;
    }
    .word-generator-mobile-ticket-frame-wrap .word-generator-mobile-body-reel-container {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .word-generator-mobile-ticket-number {
        -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
        -webkit-text-orientation: mixed;
                text-orientation: mixed;
        border-right: dashed 3px red;
        color: var(--default-blue);
        font-size: 18px;
        font-weight: 900;
        text-align: center;
    }
    .word-generator-mobile-ticket-title {
        position: absolute;
        top: 0;
        left: 0;
        color: var(--default-blue);
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        padding-top: 20px;
    }
    .word-generator-mobile-ticket-stars {
        position: absolute;
        bottom: 0px;
        left: 0;
        color: var(--default-red);
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
    }
}
.intro-card {
    box-shadow: 0px 0px 10px -5px #333;
    margin-top: -100px;
}

.intro-card-header {
    background: radial-gradient(circle, rgba(84, 157, 214, 1) 0%, rgba(6, 68, 112, 1) 0%, rgba(6, 68, 112, 1) 10%, var(--dark-blue) 100%);
}

.intro-card-header h3 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.842);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.intro-card-content {
    padding: 30px;
    font-size: 18px;
}

@media (min-width:768px) {
    .intro-card-content * {
        font-size: 18px;
    }
}

@media (min-width:992px) {
    .intro-card-content * {
        font-size: 22px;
    }
}
.page-404 {
    margin-top: 60px;
}

.page404-img {
    width: 100%;
}

.page404-text {
    text-align: center;
}

.page404-link,
.page404-link a {
    color: var(--default-blue);
    text-align: center;
    text-decoration: none;
}
