.header-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    width: 100%;
    height: 8vh;
    min-height: 70px;
}

.header-navbar {
    height: 100%;
    width: 100%;
}

.header-navbar-logo {
    height: 100%;
    width: 50%;
    position: relative;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.header-navbar-logo img {
    width: 80%;
    margin-left: 10%;
}

@media (min-width: 768px) {
    .header-navbar-logo {
        height: 110%;
        width: 15%;
        min-width: 200px;
        max-height: 90px;
        position: relative;
        background-color: #fff;
        padding: 10px 25px;
    }
    .header-navbar-logo img {
        position: absolute;
        width: 80%;
        transform: translate(0%, 0%);
        margin: auto;
    }
}