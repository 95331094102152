.selector-lucky-wheel {
    font-size: 60px;
    color: var(--default-blue);
    position: absolute;
    text-align: center;
    top: calc(var(--size, 200) / 25 * 1px + 5px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
    text-shadow: 1px 1px 5px #000000;
}

.pie {
    border-radius: 100%;
    height: calc(var(--size, 200) * 1px);
    overflow: hidden;
    position: relative;
    width: calc(var(--size, 200) * 1px);
    border: solid calc(var(--size, 200) / 25 * 1px) #fff !important;
}

.pie__segment {
    --a: calc(var(--over50, 0) * -100%);
    --b: calc((1 + var(--over50, 0)) * 100%);
    --degrees: calc((var(--offset, 0) / 100) * 360);
    -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
    height: 100%;
    position: absolute;
    transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
    transform-origin: 50% 100%;
    width: 100%;
    z-index: calc(1 + var(--over50));
}

.pie__segment:after,
.pie__segment:before {
    background-color: var(--bgc);
    background-image: var(--bg);
    background-position: 8% 4%;
    background-size: 20% 20%;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
}

.pie__segment:before {
    --degrees: calc((var(--value, 45) / 100) * 360);
    transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
    transform-origin: 50% 0%;
}

.pie__segment:after {
    opacity: var(--over50, 0);
}

.box-btn-spin-lucky-wheel {
    margin-top: 40px;
    width: 100%;
    position: absolute;
    bottom: -60px;
}

.btn-spin-lucky-wheel {
    animation: shake;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

.btn-spin-lucky-wheel:disabled {
    animation: none;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

.lucky-wheel-winner-text {
    text-align: center;
    padding: 15px;
    content: '';
    min-height: 60px;
}

.lucky-wheel-winner-text span {
    color: var(--default-blue);
    font-size: 22px;
    font-weight: 700;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}