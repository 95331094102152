:root {
    --default-background: linear-gradient(15deg, rgb(247, 165, 165) 0%, rgba(217, 43, 76, 1) 20%, rgba(217, 43, 76, 1) 70%, rgb(247, 165, 165) 80%, #064470 100%);
    --default-blue: #064470;
    --default-blue-shadow: #00010275;
    --default-blue-shadow-mobile: #0001029f;
    --dark-blue: #0e1336;
    --high-blue: #549dd6;
    --default-secondary: #8499a8;
    --high-secondary: #bac4cc;
    --default-red: #dc3545;
}