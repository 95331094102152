.extra-section-header {
    padding: 30px;
    text-align: center;
}

.extra-section-header h1 {
    color: var(--default-blue);
    font-weight: 700;
    font-size: 40px;
}

.extra-section-container {
    padding: 100px 10px;
}

.extra-section-body {
    display: flex;
    flex-wrap: wrap;
}

.extra-section-body p,
.extra-section-body b {
    color: var(--default-blue);
    font-size: 18px;
    text-align: center;
}

.extra-section-body h1 {
    color: var(--default-blue);
    font-weight: 700;
    font-size: 22px;
    margin-top: 80px;
    text-align: center;
}

@media (min-width: 768px) {
    .extra-section-body p,
    .extra-section-body b {
        color: var(--default-blue);
        font-size: 22px;
        text-align: left;
    }
    .extra-section-body h1 {
        color: var(--default-blue);
        font-weight: 900;
        font-size: 60px;
        text-align: left;
        margin: 0;
        margin-bottom: 50px;
    }
}

@media (min-width: 1200px) {
    .extra-section-body p,
    .extra-section-body b {
        color: var(--default-blue);
        font-size: 22px;
        text-align: left;
    }
    .extra-section-body h1 {
        color: var(--default-blue);
        font-weight: 900;
        font-size: 80px;
        text-align: left;
        margin: 0;
        margin-bottom: 80px;
    }
}